<template>
    <b-modal no-close-on-backdrop no-close-on-esc id="modal_export" size="md"  :hide-header="true" hide-footer>
          <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
            <i class="text-danger fa fa-times fa-lg"></i>
            </button>
        </div>
        <div v-if="loading">
            <b-spinner class="spinner" variant="success" label="Carregando"></b-spinner> <i>Aguarde</i>
        </div>
        <div v-if="loading==false">
            <div class="card h-100 py-1" v-if="pid == ''">
                <h4><b>{{name}}</b></h4>
                <div class="session-gap"></div>
                <div class="session-gap"></div>
                <p class="info">Você confirma a exportação {{text}}?</p>
                <div class="session-gap"></div>
                <mdb-input label="Enviar para o email" v-model="email" small=""/>
                <div v-if="noticeEmailDownload != false" v-html="noticeEmailDownload" class="info"></div>
                <div v-if="errorMsg != false" v-html="errorMsg" class="alert alert-danger mt-2"></div>
                <div class="d-flex buttons justify-content-center">
                    <btn @enviar="enviar" action="enviar" name="Confirmar" type="fill" :noMargin='true'/>
                </div>
            </div>
            <div class="card h-100 py-1" v-else>
                <template v-if="downloadPercent != 100">
                    <h4 ><b>A exportação está sendo processada</b></h4>
                    <p class="info" v-if="email != ''">Ao finalizar o resultado será enviado para o seguinte e-mail: {{email}}.</p>
                    <p v-else>Ao finalizar será exibida a janela para baixar o arquivo.</p>
                    <b-progress :value="downloadPercent" :max="100" show-progress animated></b-progress>
                    <div v-if="errorMsg != false" class="alert alert-danger mt-2">
                        <span v-html="errorMsg"></span>
                    </div>
                </template>
                <template v-else>
                    <h4 ><b>A exportação foi concluída</b></h4>
                    <div class="d-flex buttons justify-content-center mt-3">
                        <btn v-if="downloadData.downloadUrl.indexOf(';') == -1 && downloadData.downloadUrl != ''"  @download="download" action="download" name="Baixar" type="fill" :noMargin='true'/>
                    </div>
                    <div v-if="errorMsg != false" class="alert alert-info mt-2">
                        <span v-html="errorMsg"></span>
                    </div>
                </template>
            </div>
        </div>
    </b-modal>
</template>

<script>
import barramento from '@/barramento'
import api from '@/services/api.js'
import { mdbInput } from 'mdbvue'
import btn from '@/components/forms/Button.vue'

export default {
    components: {
        mdbInput,
        btn
    },
    data() {
        return {
            messages: [],
            errorMsg: false,
            type: "",
            query: "",
            text: "",
            email: "",
            name: '',
            pid: '',
            downloadData: [],
            downloadPercent: 0,
            urlDownload: '',
            loading: false,
            noticeEmailDownload: 'Opcional',
            totalResult: 0,
            totalResultSelected: [],
        }
    },
    created() {
        this.totalResult = 0
        this.totalResultSelected = []
        barramento.$on('totalResult', newValue => {
            this.totalResult = newValue;
        });
        barramento.$on('totalResultSelected', newValue => {
            this.totalResultSelected = newValue;
        });
        barramento.$on('modal_export', query => {

            var type = query.indexOf("export=") + 7
            this.type = query.slice(type, type + 3)
            this.query = query

            if(this.type == 'row'){
                this.text = 'da tabela '
                this.name = 'Exportar Tabela'
                this.noticeEmailDownload = 'Opcional'
            } else if (this.type == 'reg'){
                this.text = 'de registro(s)'
                this.name = 'Exportar Registro'
                this.noticeEmailDownload = 'Para mais de 5.000 registros, obrigtório informar o e-mail.'
                    + '<br>Veja <a href="https://support.microsoft.com/pt-br/office/abrir-arquivos-utf-8-do-csv-corretamente-no-excel-8a935af5-3416-4edd-ba7e-3dfd2bc4a032" target="_blank">neste link</a> como abrir arquivos CSV corretamente no Excel.';
            } else {
                this.text = 'de ficha(s) no formato PDF em um arquivo ZIP'
                this.name = 'Baixar Fichas Atuais'

                if(this.totalResultSelected.length > 0 && this.totalResultSelected.length <= 5000){
                    this.noticeEmailDownload = 'Opcional'
                }else if(this.totalResultSelected.length > 5000 || this.totalResult > 5000){
                    this.noticeEmailDownload = 'Para mais de 5.000 fichas, obrigtório informar o e-mail.'
                }
            }

            this.$bvModal.show('modal_export')
        })

    },
    watch: {
        async pid() {
            if(this.pid != '') {
                var t = this
                var endpoint = '/job/' + t.pid
                setTimeout(function() {
                    t.getJob(endpoint,t);
                }, 3000)
            }
        },
        downloadData(){
            this.errorMsg = false;
            if(this.downloadData.length != 0){
                if( this.downloadData['message'] && this.downloadData.percent < 100 ){
                    this.errorMsg = this.downloadData['message'];
                    return;
                }

                this.downloadPercent = this.downloadData['percent']

                if( this.downloadData['message'] ) {
                    this.errorMsg = this.downloadData['message'];
                }

                if(this.downloadPercent == 100){
                    this.urlDownload = this.downloadData['downloadUrl'];
                } else {
                    var t = this
                    var endpoint = '/job/' + this.pid
                    setTimeout(function() {
                        api.get(endpoint).then(response => (
                            t.downloadData = response.data.data
                        ))
                    }, 4000)
                }
            }
        }
    },
    methods: {
        async getJob(endpoint,vm) {
          await api.get(endpoint).then(response => (
                vm.downloadData = response.data.data
            ))
        },
        async enviar() {
            if (this.type == 'pdf'){
                let validaRequest = true
                if( this.totalResultSelected.length > 0){
                    if(this.totalResultSelected.length > 500){
                        validaRequest = false
                    }
                }else if(this.totalResult > 500){
                    validaRequest = false
                }
                if (validaRequest == false){
                    if(this.email.trim() == ''){
                        alert('Para mais de 500 fichas, obrigtório informar o e-mail.')
                        return
                    }
                }
            }
            window.grecaptcha.ready(async () => {
                let token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
                this.onRecaptchaReady(token);
            });
        },
        async onRecaptchaReady(token){
          var t = this
          var newQuery = ''
          this.pid = ''
          this.errorMsg = '';
          t.messages = [];
          if (this.email == "") {
            newQuery = this.query
          } else {
            if (!this.isEmail(this.email)) {
              this.errorMsg = 'Email inválido';
              return;
            }
            newQuery = this.query + '&email=' + this.email
          }
          this.loading = true
          let urlParts = newQuery.split('?');
          let endPoint = urlParts[0];
          let query = urlParts[1]
          let searchParams = {};
          query.split("&").forEach(function (part) {
            let item = part.split("=");
            searchParams[item[0]] = decodeURIComponent(item[1]);
          });
          searchParams.reCaptchaToken=token;
          const ps = new URLSearchParams(searchParams);
          const axiosConfig = {headers: {'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded', "Access-Control-Allow-Origin": "*"}};

          await api.post(endPoint, ps, axiosConfig).then(function (response) {
            if (response.data.status == 200) {
              t.messages.push(response.data.data)
              this.pid = t.messages[0]['pid']
            } else {
              this.errorMsg = response.data.msg;
            }
          }.bind(this)).finally(() => {
            this.loading = false
          })
        },
        download(e){
            e.preventDefault()
            this.errorMsg = "Baixando planilha...";
            alert('Este procedimento poderá demorar alguns segundos ao terminar será exibida a janela para salvar o arquivo. Clique em [OK] para iniciar.');
            window.open(this.urlDownload,'_self');
            this.close()
            //window.location.href=this.urlDownload;
        },
        close(){
            this.pid = ''
            this.downloadData = []
            this.$bvModal.hide('modal_export')
        }

    }
}
</script>

<style lang="scss">
@import '@/web/scss/form.scss';
    #modal_export {
        .modal-dialog .modal-content {
            border-radius: 15px !important;
            padding: 30px !important;
        }

        .modal-dialog .modal-body {
          padding: 1.25rem;
        }

        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        .session-gap {
            height: 15px;
        }
        .info {
            font-size: 14px;
            color: #646464
        }
    }
</style>
